<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--vandar login-->
            <b-modal
                    ref="vandar-modal"
                    title="ورود به وندار"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو"
                    v-model="vandarModal"
                    @ok.prevent="vandarLogin"
            >
                <b-form>
                    <b-form-group class="mt-1" label="نام کاربری حساب وندار">
                        <b-form-input v-model="vandar.mobile" placeholder="نام کاربری"/>
                    </b-form-group>
                    <b-form-group class="mt-1" label="رمز عبور حساب وندار">
                        <b-form-input v-model="vandar.password" placeholder="رمز عبور" type="password"/>
                    </b-form-group>
                </b-form>
            </b-modal>

            <!--toman transaction manual-->
            <b-modal
                    ref="toman-modal"
                    title="پرداخت دستی"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو"
                    v-model="tomanModal"
                    @ok.prevent="successAlert()"
            >
                <b-overlay :show="loading"
                           rounded="sm">
                    <b-form label="">
                        <p>
                            مقدار <span class="text-primary">{{ $toLocal(label.amount ? label.amount : 0) }}</span>
                            تومان را به شماره شبای
                            <span class="text-primary">
                            {{ label.account ? label.account.sheba : '' }}
                        </span>
                            ارسال نمایید. بعد از انجام تراکنش به صورت دستی شماره پیگیری را در قسمت زیر وارد نمایید و سپس
                            روی
                            دکمه
                            تایید پرداخت کلیک نمایید
                        </p>
                        <b-form-group class="mt-1" label="شماره پیگیری پرداخت">
                            <b-form-input v-model="transactionHash" placeholder="شماره پیگیری"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <!--crypto withdraw manual-->
            <b-modal
                    ref="crypto-modal"
                    title="پرداخت رمزارز"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو"
                    v-model="cryptoModal"
                    @ok.prevent="payCryptoManually()"
                    @cancel="selectedWallet=''"
            >
                <b-form style="min-height: 250px" @click.stop.prevent="cryptoModal=true">
                    <p>مقدار
                        <span class="text-primary">{{ label.coin ? label.coin.code : '' }}
                            {{ label.amount ? label.amount : '' }}</span>
                        را به شماره کیف پول

                        <span class="text-primary">{{ label.payment ? label.payment.address : '' }}</span>

                        ارسال نمایید. بعد از انجام تراکنش به صورت دستی شماره تراکنش (tx id) را در قسمت زیر وارد نمایید و
                        سپس
                        روی
                        دکمه
                        تایید پرداخت کلیک نمایید
                    </p>
                    <b-form-group class="mt-1" label="شماره تراکنش پرداخت">
                        <b-form-input v-model="txId" placeholder="شماره تراکنش"/>
                    </b-form-group>
                </b-form>
            </b-modal>

            <div class="m-1 d-flex justify-content-between"
                 dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
                    <!--                    <feather-icon icon="FilterIcon"/>-->
                    <img width="15" src="../../assets/images/excel.png" alt="" class="mr-50"
                         style="filter: invert(100%)"/>
                    خروجی اکسل درخواست های برداشت
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(email)="data">
                        <span dir="ltr">
                    {{ data.item.user.email }}
                        </span>
                    </template>

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                    {{ $G2J(data.item.created_at, 'faTime') }}
                        </span>
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount) }}
                        </span>
                    </template>

                    <template #cell(destination)="data">
                        <span dir="ltr">
                            {{
                                data.item.account ? data.item.account.account_number : data.item.payment ? data.item.payment.address : ''
                            }}
                        </span>
                    </template>

                    <template #cell(coin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="data.item.coin.logo"
                                variant="light-info"
                        />
                        {{ data.item.coin.label }}
                    </template>

                    <template #cell(action)="{item}">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                                 @click="item.coin.code === 'TOMAN'?
                                    selectTomanPaymentModal(item)
                                    : selectCryptoPaymentModal(item)"
                                >
                                    <feather-icon class="text-success" icon="CheckSquareIcon"/>
                                    <span class="text-success align-middle ml-50">انجام تراکنش</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="cancelRequest(item)">
                                    <feather-icon class="text-danger" icon="XCircleIcon"/>
                                    <span class="text-danger align-middle ml-50">لغو درخواست</span>
                                </b-dropdown-item>

                                <!--                            <b-dropdown-item>-->
                                <!--                                <feather-icon icon="FileTextIcon"/>-->
                                <!--                                <span class="align-middle ml-50">جزییات سفارش</span>-->
                                <!--                            </b-dropdown-item>-->
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    // BBadge,
    BAvatar,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    // BListGroup,
    // BListGroupItem,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'Withdraw',
    components: {
        NotAllowed,
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        // BBadge,
        BAvatar,
        vSelect,
        BForm,
        BFormInput,
        BFormGroup,
        BOverlay,
        // BListGroup,
        // BListGroupItem,

    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 20,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        label: {},
        transactionHash: '',
        txId: '',
        tomanModal: false,
        vandarModal: false,
        cryptoModal: false,
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        vandar: {
            mobile: '',
            password: ''
        },
        columns: [
            {
                label: 'نام کاربر',
                key: 'email',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'مقدار درخواستی',
                key: 'amount',
                sortable: true,
                searchType: 'number'
            },
            {
                label: 'واحد درخواست',
                key: 'coin',
                sortable: false,
                searchType: 'text',
            },
            {
                label: 'حساب مقصد',
                key: 'destination',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'تاریخ درخواست',
                key: 'created_at',
                sortable: false,
                searchType: 'date'
            },
            {
                label: 'عملیات',
                key: 'action'
            },
        ],
        walletName: '',
        walletUnit: '',
        request: '',
        selectedWallet: '',
        walletsList: '',
        password: '',
        loading: false,
        wallets: [
            {name: 1, totalBalance: 52},
            {name: 0, totalBalance: 85},
            {name: 100, totalBalance: 22},
        ],
    }),
    computed: {
        statusVariant() {
            const a = {
                inactive: 'danger',
                active: 'success',
            }
            return e => a[e];
        },
        statusLabel() {
            const a = {
                inactive: 'لغو شده',
                active: 'انجام شده',
            }
            return e => a[e];
        },
    },
    methods: {
        selectTomanPaymentModal(e) {
            this.label = e
            this.tomanModal = true

            // this.$bvModal
            //     .msgBoxConfirm('لطفا طریقه انجام تراکنش را انتخاب کنید.', {
            //         title: 'شیوه پرداخت',
            //         size: 'sm',
            //         okVariant: 'primary',
            //         okTitle: 'درگاه بانکی',
            //         cancelTitle: 'پرداخت دستی',
            //         cancelVariant: 'outline-secondary',
            //         hideHeaderClose: false,
            //         centered: true,
            //     })
            //     .then(value => {
            //         if (value) {
            //             this.doVandarPayment()
            //         } else if (value === false) {
            //             this.tomanModal = true
            //         }
            //     })
        },

        selectCryptoPaymentModal(e) {
            this.label = e
            this.transactionHash = ''
            this.$bvModal
                .msgBoxConfirm('لطفا طریقه انجام تراکنش را انتخاب کنید.', {
                    title: 'شیوه پرداخت',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'اتوماتیک',
                    cancelTitle: 'دستی',
                    cancelVariant: this.state.exchangeIsAuto ? 'outline-primary' : 'none',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        this.autoDepositRequest()
                    } else if (value === false) {
                        this.cryptoModal = true
                    }
                })
        },

        async successAlert() {
            if (!this.loading) {
                this.loading = true
                try {
                    await this.$axios.post('/transactions/' + this.label.id + '/accept',
                        {
                            payment_method: "TRANSFORM",
                            ref_id: this.transactionHash
                        }
                    )
                    this.$swal({
                        icon: 'success',
                        title: `تراکنش با شماره پیگیری ${this.transactionHash} پرداخت گردید.`,
                        confirmButtonText: 'تایید',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                    this.loading = false
                    this.tomanModal = false
                    this.label = {}
                    this.transactionHash = 0
                    this.getData()
                } catch (e) {
                    this.loading = false
                }
            }
        },
        async doVandarPayment() {
            try {
                this.state.loading = true
                await this.$axios.post('/transactions/' + this.label[2] + '/accept', {"payment_method": "ONLINE"})
                this.$error('پرداخت با موفقیت انجام شد', '', 'success')
                this.getData(this.currentPage, this.perPage)
            } catch (e) {
                if (e.response.data.message.includes('REFRESH_TOKEN')) {
                    this.$error('نیازمند به ورود',)
                    this.vandarModal = true
                } else {
                    const err = JSON.parse(e.response.data.errors[0].match(/\[{(.*?)\}]/)[0])[0]
                    this.$error(err.error, err.errors.amount)
                }
            }
        },
        async vandarLogin() {
            this.state.loading = true
            await this.$axios.post('/psp/login', this.vandar)
            this.$error('توکن به روز شد. لطفا مجددا درخواست برداشت را بدهید.', '', 'success')
        },
        payCryptoManually() {
            this.$swal({
                title: "آیا از انجام تراکنش مطمئن هستید؟",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'انجام تراکنش',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.state.loading = true
                    const address = '/transactions/' + this.label.id + '/accept'
                    await this.$axios.post(address, {tx_id: this.txId})

                    this.cryptoModal = false
                    this.txId = ''

                    this.$swal({
                        icon: 'success',
                        title: 'تراکنش انجام شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                    this.getData(this.currentPage, this.perPage)
                }
            })
        },

        async downloadExcel() {
            await this.$axios(
                {
                    url: '/transactions',
                    method: 'GET',
                    responseType: 'blob', // important
                    params: {
                        export: 'excel',
                        type: 'withdraw',
                        status: 'pending',
                        ...this.$route.query
                    }
                }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xls'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },

        async autoDepositRequest() {
            if (!this.loading) {
                this.loading = true
                await this.$axios.post('/transactions/' + this.label.id + '/accept/auto')
                this.$swal({
                    icon: 'success',
                    title: `تراکنش با شماره پیگیری ${this.payment.tx_id} ثبت شد`,
                    confirmButtonText: 'تایید',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

                this.loading = false
                this.tomanModal = false
                this.transactionHash = 0
                this.label = {}
                this.getData()
            }
        },

        cancelRequest(e) {
            this.$swal({
                icon: 'error',
                title: "آیا از لغو درخواست کاربر مطمئن هستید؟",
                confirmButtonText: 'تایید و لغو درخواست',
                showCancelButton: true,
                cancelButtonText: 'خیر',
                text: "مشخصات درخواست : کاربر " + e.user.profile.name + " مقدار " + this.$toLocal(e.amount) + " " + e.coin.label,
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-primary ml-1',
                },
            }).then(async (result) => {
                if (result.value) {
                    this.state.loading = true
                    const address = '/transactions/' + e.id + '/reject'
                    await this.$axios.post(address)

                    await this.getData(this.currentPage, this.perPage)

                    this.$swal({
                        icon: 'success',
                        title: 'سفارش لغو شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },

        sort(e) {
            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },

        async getData(page = 1, perPage = this.perPage) {
            this.state.loading = true

            const queryParams = {
                size: perPage,
                page: page,
                type: 'withdraw',
                status: 'pending',
                ...this.$route.query
            }
            const res = await this.$axios('/transactions', {params: queryParams})

            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },
    },
    created() {
        this.getData()
    }
}
</script>
<style lang="scss">
.vs__selected {
  width: calc(100% - 20px);
}

[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

.btn-none {
  display: none;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
